#drive {

    // Sidenav
    .sidenav {
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        padding: 0;

        &.md-locked-open {
            width: 320px;
            min-width: 320px;
            max-width: 320px;
            overflow: hidden;
        }

        .header {
            height: 125px;
            min-height: 135px;
            max-height: 135px;
            padding: 24px 24px;

            .title {
                font-size: 15px;
                margin-bottom: 8px;
            }

            .subtitle {

            }
        }

        .content {
            position: relative;
            background: #FFFFFF;

            .file-details {

                .preview {
                    background: #FFFFFF;
                    height: 240px;
                }

                .offline-switch {
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    font-weight: 500;

                    md-switch {
                        margin: 0;

                        .md-container {
                            margin: 0;
                        }
                    }
                }

                .title {
                    padding: 24px 24px 16px 24px;
                }

                table {
                    padding: 0 24px;
                    width: 100%;
                    text-align: left;

                    tr {

                        th, td {
                            padding: 16px 0;
                            width: 25px;
                        }

                        th {

                        }

                        td {

                        }

                        &.type {
                            text-transform: capitalize;
                        }

                        &.size {
                        }

                        &.location {
                        }

                        &.owner {
                            text-transform: capitalize;
                        }

                        &.opened {
                        }

                        &.created {
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #drive {

        .sidenav {

            .header {
                height: 160px;
                min-height: 160px;
                max-height: 160px;
            }
        }
    }
}
