md-content.autocomplete {
  min-height: 250px;
  input {
    min-width: 400px;
  }
}

.md-chips .md-chip-input-container {
    md-autocomplete {
        input {
            min-width: 600px;
        }
    }
}

.md-item-text.compact {
    padding-top: 8px;
    padding-bottom: 8px;
}

.contact-item {
  box-sizing: border-box;

  .md-list-item-text {
    padding: 14px 0;
    max-width: 350px;
    h3 {
              margin: 0 !important;
      padding: 0;
      line-height: 1.2em !important; 
    }
    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; 
    }
}

  &.selected {
    opacity: 0.5;
    h3 {
        opacity: 0.5;
    }
  }
}


.fixedRows {
  height: 250px;
  overflow: hidden;
}

.md-chips {
  padding: 5px 0 8px;
}
md-contact-chips {
  margin-bottom: 10px;
}







md-dialog {

    &.task-dialog {
        max-width: 720px;
        width: 720px;

        md-dialog-content {
            display: block;
            position: relative;
            margin-top: 15px;
        }

        md-toolbar {

            .title {
                font-size: 17px;
                margin: 20px 0px;
            }
        }

        md-input-container {

            textarea {
                min-height: 150px;
            }
        }

        .date-container {
            padding: 16px 0 24px 0;
        }

        .notes {

            > label {
                color: rgba(0, 0, 0, 0.54);
            }

            .mce-tinymce {
                margin-top: 8px;
            }
        }

        .tags {
            margin: 8px 0;

            label {
                font-size: 11px;
                color: rgba(0, 0, 0, 0.54);
            }

            .md-chips {
                font-size: 13px;

                .md-chip {

                    md-chip-template {

                        .color {
                            display: inline-block;
                            position: relative;
                            width: 8px;
                            height: 8px;
                            top: -1px;
                            border-radius: 50%;
                            margin: -2px 4px 0 0;
                        }
                    }
                }
            }
        }

        .attachment-list {
            font-size: 13px;
            padding-top: 16px;

            .attachment {
                background-color: rgba(0, 0, 0, 0.08);
                border: 1px solid rgba(0, 0, 0, 0.12);
                padding-left: 16px;
                margin-top: 8px;
                border-radius: 2px;

                .filename {
                    font-weight: 500;
                }

                .size {

                }

                .md-button {
                    margin: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        md-dialog-actions {
            position: relative;
            overflow-y: hidden;
            overflow-x: auto;
            justify-content: space-between;
            background-color: rgba(0, 0, 0, 0.03);
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-md) {

    md-dialog {

        &.task-dialog {
            width: 80%;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {

    md-dialog {

        &.task-dialog {
            width: 90%;
        }
    }
}
