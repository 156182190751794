#drive {

    .settings-sidenav {
        z-index: 51;
        box-shadow: $whiteframe-shadow-8dp;
        overflow-x: hidden;

        .md-toolbar-tools {
            height: 135px;
            min-height: 135px;
            max-height: 135px;

            .menu-title {
                font-size: 16px;
            }
        }
    }
    md-sidenav{
        background-color: #fff;
        md-content {
            overflow-x: hidden;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            &.settings {
                padding-top: 12px;
                top: 135px;

                md-list {

                    md-list-item {

                        p.title{
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        md-icon {
                            margin-top: auto;
                            margin-bottom: auto;
                        }
                    }

                }
            }

            md-divider {
                margin: 10px 0px 0px 0px;
            }
        }
    }
}
